import React, { FC } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import { AppIconButton, AppIcon } from "../../theme";
import { circleCheck, circleInfo, exclamation } from '../../theme/icons';

import "./appNotification.scss";

interface IProps {
  onCloseNotifcation?: () => void,
  message: string,
  variant: string,
  useHtml?: boolean
}

const AppNotification: FC<IProps> = ({ message, variant, onCloseNotifcation, useHtml=false }) => {

  const variantIcon = (variant:string) => {
    switch (variant) {
      case "error":
        return <AppIcon icon={exclamation} />;
      case "info":
        return <InfoIcon />;
      case "generic":
        return <AppIcon icon={circleInfo} />;
      case "message":
        return <AppIcon icon={exclamation} />;
      default:
        return <AppIcon icon={circleCheck} />;
    }
  }

  return (
    <div className="app-notification">
      <div className={`notification-wrapper ${variant}`}>
        <div className="notification-text">
          <span className={`notification-icon`}>
            {variantIcon(variant)}
          </span>
          {!useHtml && <div className="toast-content-container">{message}</div>}
          {useHtml && <div dangerouslySetInnerHTML={{__html: message}} className="toast-content-container"></div>}
        </div>
        <AppIconButton aria-label="close" className="toast-close-button" onClick={onCloseNotifcation}>
          <CloseIcon />
        </AppIconButton>
      </div>
    </div>
  );
};

export default AppNotification;
