export const getAnnouncementChunks = (state, nextAnnouncements) => {
  const { chunk } = state.announcementPaginationData;
  const existingIds = new Set(state.announcements.map(announcement => announcement.id));
  const uniqueNextAnnouncements = nextAnnouncements.filter(announcement => !existingIds.has(announcement.id));

  const newState = { ...state };
  if(uniqueNextAnnouncements?.length === 0) {
    newState.announcements = nextAnnouncements;
  }else{
    newState.announcements = [...newState.announcements, ...uniqueNextAnnouncements];
  }
  newState.announcementPaginationData = {
    isAllAnnouncementsFetched: !nextAnnouncements.length,
    chunk: chunk + 1,
    isLoading: false
  };

  return newState;
};

export const  flushAnnouncements = (state) => {
  const newState = { ...state };

  newState.announcements = [];
  newState.announcementPaginationData = {
    isAllAnnouncementsFetched: false,
    chunk: 1
  }

  return newState
}
