import { AppClass } from "../components/appCard/models/appClass";

export const SSPRolesEnum = {
  PRJADMIN: "PRJ-ADMIN",
  PRJUSR: "PRJ-USR",
  PORUSR: "POR-USR",
  PORADMIN: "POR-ADMIN",
  PORADMINCONSOLE: "POR-ADMIN-CONSL",
  PRJADMINUSER:"PRJ-USR-ADMIN"
};

export enum EProjectStatuses {
  DRFT = "DRFT",
  ACTV = "ACTV",
  LCKD = "LCKD",
  CLSD = "CLSD",
  EROR = "EROR",
  PND = "PND",
  RDY = "RDY",
  PROJECTCLOSED = "projectclosed",
}

export const PAANS_RESET_ERROR_CODE = 101;

export const PAGINATION_DATA = [
  {
    label: "Show 10",
    value: 10,
  },
  {
    label: "Show 20",
    value: 20,
  },
  {
    label: "Show 50",
    value: 50,
  },
  {
    label: "Show 100",
    value: 100,
  },
];

export const POLICY_URL = "/policy";
export const POLICY_ERROR_CODE_KEY = "errorCode";
export const POLICY_ERROR_CODE = "policynotaccepted";
export const RESTRICTED_ERROR = "project-restricted";
export const DEFAULT_TOKEN_RENEWAL_OFFSET_SECONDS = "300";
export const ERROR_URL = "/error";
export const PROJECT_RESTRICT_ERROR_CODE_KEY = "project-restricted";
export const POLICY_ERROR_KEY = "policyErrorCode";
export const POLICY_REDIRECT_URI = "policyRedirectURL";
export const POLICY_FRIENDLY_ID_KEY = "projectFriendlyId";
export const POLICY_REDIRECT_KEY="redirectURI"
export const REPLAY_ERROR=433
export const IMAGE_FILE_RESPONSE_TYPE = "arraybuffer";

export enum DropZoneErrorStatuses {
  FILE_TOO_LARGE = "file-too-large",
  FILE_TOO_SMALL = "file-too-small",
  TOO_MANY_FILES = "too-many-files",
  FILE_INVALID_TYPE = "file-invalid-type",
}

export enum EWorkFlowTableName {
  COMMUNICATIONS = "Communications",
  COMMUNICATION_TEMPLATES = "CommunicationTemplates",
  WORKFLOW_DEFINITIONS = "WorkflowDefinitions",
  WORKFLOW_SCHEDULES = "WorkflowSchedules",
  WORKFLOW_EXECUTIONS = "WorkflowExecutions",
  WORKFLOW_ERROR_LOG = "WorkflowErrorlog",
  COMMUNICATION_TEMPLATE_CATEGORY = "CommunicationTemplateCategories",
}

export enum ProjectActiveFeatures {
  PPEDD_APPROVER = "ppedd-approver",
  MANAGE_ASSISTANT = "ce-assistant",
}

export enum ProjectSteps {
  Step_1 = "1",
  Step_2 = "2",
  Step_3 = "3",
}

export enum HandlerMode {
  CLOSE_REMINDER = "closeReminder",
  ConfirmOrChangeCategory = 'confirmOrChangeCategory',
}

export enum userType {
  Guest = "Guest",
  Member = "Member",
}

export enum UserTypes {
  Internal = "Internal",
  External = "External"
}

export const completeStateClass = 'complete-state';
export const progressStateClass = 'no-progress-state';
export const addNewNotification="Add new notification";
export const CE4= "CE4"
export const notificationStatus ={
  Fetching:"Fetching",
  Saving:"Saving"
}
 
export enum FeatureSupportedKeys {
  Workflow = "workflow"
}

export enum RoutePathKeys {
  PROJECT = "project"
}

export enum SessionKeys {
  HOME_PAGE_PROJECT_TILE_CLICK = "HomePageProjectTileClick",
  CE_SERVER_TIME = "ce-ssp-server-time",
}

export const AppKeysToMoveFirst = ["CE4-PMO", "WRIKE-PMO"];

export const sortAppOrder = (item: AppClass): number => {
  const index = AppKeysToMoveFirst.indexOf(item.key);
  return index !== -1 ? index : AppKeysToMoveFirst.length;
}

export const HomePageLookupTableNames = ["UserRoles","SubServiceLines","ServiceLines","ProjectStatuses","Icons","AppPlatformClasses","Sectors", "SubSectors", "SubSubSectors"];
export const assistantCancelEvent = "CANCEL_CLICKED";
export const cancelWithoutSaving = "CANCEL_WITHOUT_SAVING";
export const externalAppSettings = {
  type: "COPILOT_EXTERNAL_APP_SETTINGS",
  payload: {}
};
export const assistantSettingURl = "/configurations";
export const VisibleAppCountsByRes =  [ 
  { width: 1900, count: 11 },
  { width: 1700, count: 9 },
  { width: 1500, count: 7 },
  { width: 1400, count: 5 },
  ]

export const ProjectDbsFolderPath = "dbs";
export const AppInsightsUrl="https://dc.services.visualstudio.com/v2/track";

// home sorting dropdown data
export const HomeSortData = [
  { key: 'name', value: 'Project Name' },
  { key: 'lastAccessedOn', value: 'Last Accessed Date' },
  { key: 'createdOn', value: 'Creation Date' }
];
