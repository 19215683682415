import React, { useEffect, useState, useRef, useCallback } from "react";
import en_translation from "../../localization/en/translation.json";
import { AppButton, AppIcon, AppToggleSwitch, AppAlert } from "../../theme";
import { add, trash, editPen } from "../../theme/icons";
import List from "../../components/editableGrid/list/list";
import axios from "axios";
import { API_URL } from "../../store/api";
import "./quickLinks.scss";
import { GridApi } from "ag-grid-community";
import {
  validateGUID,
  validateProfessionalUrl,
  validateFormInputForSpecialChar,
} from "./../../utils/helper-utility";
/* eslint-disable */
type Props = {
  projectId: string;
  isAdmin: boolean;
  isInternalUser: boolean;
};

type Link = {
  id: string;
  title: string;
  url: string;
  visibility: boolean;
};

type Error = {
  id: string;
  title?: string;
  url?: string;
};

const QuickLinks: React.FC<Props> = ({
  projectId,
  isAdmin,
  isInternalUser,
}) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [isNewLink, setIsNewLink] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<string | null>(null); // Track which row is being edited
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [titleErrorList, setTitleErrorList] = useState<Error[]>([]);
  const [urlErrorList, setUrlErrorList] = useState<Error[]>([]);

  const setTitleError = (error: Error | null) => {
    if (error) {
      setTitleErrorList((prevErrors) => [...prevErrors, error]);
    } else {
      setTitleErrorList([]);
    }
  };
  const setUrlError = (error: Error | null) => {
    if (error) {
      setUrlErrorList((prevErrors) => [...prevErrors, error]);
    } else {
      setUrlErrorList([]);
    }
  };

  // Quick Links API
  const getProjectQuickLinks = useCallback(
    async (projectId: string) => {
      const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
      try {
        setIsLoading(true);
        const response = await axios.get(url);
        setLinks(response.data);
        setIsNewLink(false);
        setIsEditing(null);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching project quick links:", error);
      }
    },
    [projectId]
  );

  const addProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links`;
    try {
      await axios.post(url, link);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error adding project quick link:", error);
    }
  };

  const updateProjectQuickLink = async (projectId: string, link: Link) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${link.id}`;
    try {
      await axios.put(url, link);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error updating project quick link:", error);
    }
  };

  const deleteProjectQuickLink = async (projectId: string, linkId: string) => {
    const url = `${API_URL}/api/v1/projects/${projectId}/quick-links/${linkId}`;
    try {
      await axios.delete(url);
    } catch (error) {
      getProjectQuickLinks(projectId);
      console.error("Error deleting project quick link:", error);
    }
  };

  useEffect(() => {
    getProjectQuickLinks(projectId);
  }, [getProjectQuickLinks, projectId]);

  const handleCellValueChanged = (params: any) => {
    if (validateGUID(params.data.id)) {
      updateProjectQuickLink(projectId, params.data);
      setLinks((prevLinks) =>
        prevLinks.map((link) =>
          link.id === params.data.id ? params.data : link
        )
      );
    } else {
      addProjectQuickLink(projectId, params.data);
      setIsNewLink(false);
    }
  };

  useEffect(() => {
    if (isNewLink && links.length > 0 && !validateGUID(links[0]?.id)) {
      listRef.current?.api.setFocusedCell(0, "title");
      listRef.current?.api.startEditingCell({
        rowIndex: 0,
        colKey: "title",
      });
    }
  }, [isNewLink, links]);

  const handleDelete = async (data: any) => {
    if (validateGUID(data.id) && links.filter((x) => x.id === data.id).length) {
      setLinks(links.filter((x) => x.id !== data.id));
      await deleteProjectQuickLink(projectId, data.id);
    }
    removeRowFromGrid(data);
    setUrlErrorList(urlErrorList.filter((x) => x.id !== data.id));
    setTitleErrorList(titleErrorList.filter((x) => x.id !== data.id));
  };

  const handleAddLink = () => {
    setIsNewLink(true);
    setIsEditing(links.length.toString());
    const newLink = {
      id: links.length.toString(),
      title: "",
      url: "",
      visibility: false,
    };
    listRef.current?.api.applyTransaction({ add: [newLink] });
    setLinks((prevLinks) => [...prevLinks, newLink]);
  };

  const onGridReady = (gridRef: any) => {
    listRef.current = gridRef;
    if (isNewLink && links.length <= 1) {
      listRef.current?.api.setFocusedCell(links.length - 1, "title");
      listRef.current?.api.startEditingCell({
        rowIndex: links.length - 1,
        colKey: "title",
      });
    }
  };

  const removeRowFromGrid = (data: any) => {
    listRef.current!.api.applyTransaction({ remove: [data] });
    listRef.current!.api.stopEditing();
    if (isEditing === data.id) {
      setIsEditing(null);
      setIsNewLink(false);
    }
  };

  const isEmptyRow = (params: any) => {
    if (!params.data.title && !params.data.url) {
      removeRowFromGrid(params.data);
      setLinks(links.filter((x) => x.id !== params.data.id));
      setIsEditing(null);
      setIsNewLink(false);
      return true;
    }
    return false;
  };

  const isValidData = (params: any) => {
    setUrlErrorList(urlErrorList.filter((x) => x.id !== params.data.id));
    setTitleErrorList(titleErrorList.filter((x) => x.id !== params.data.id));

    if (
      params.data.title == "" ||
      validateFormInputForSpecialChar(params.data.title)
    ) {
      setTitleError({
        id: params.data.id,
        title: en_translation.quick_link_title_warning,
      });
      return false;
    } else {
      setTitleErrorList(titleErrorList.filter((x) => x.id !== params.data.id));
    }

    if (params.data.url == "" || validateProfessionalUrl(params.data.url)) {
      setUrlError({
        id: params.data.id,
        url: en_translation.quick_link_url_warning,
      });
      return false;
    } else {
      setUrlErrorList(urlErrorList.filter((x) => x.id !== params.data.id));
    }

    return true;
  };

  const onRowEditingStopping = (params: any) => {
    if (isEmptyRow(params)) {
      return;
    }
    isValidData(params) && handleCellValueChanged(params);
    setIsEditing(null);
    setIsNewLink(false);
  };

  const actionCellRenderer = (props: any) => {
    const { data } = props;
    return (
      <div>
        {isAdmin && (
          <>
            <AppIcon
              icon={editPen}
              className="edit-icon"
              name="edit"
              onClick={() => onCellClicked(props)}
            />
            <AppIcon
              icon={trash}
              className="fa-thin delete-icon"
              name="delete"
              onClick={() => handleDelete(data)}
            />
          </>
        )}
      </div>
    );
  };

  const onCellClicked = (params: any) => {
    setIsEditing(params.data.id);
    params.api.startEditingCell({
      rowIndex: params.node.rowIndex,
      colKey: "title",
    });
  };

  const handleToggleClick = (params: any, checked: boolean) => {
    const updatedLink = params.data;
    updatedLink.visibility = checked;
    params.setValue(checked);
    params.api.applyTransaction({ update: [updatedLink] });
    onCellClicked(params);
  };
  const toggleRender = (params: any) => {
    return (
      isAdmin && (
        <AppToggleSwitch
          newDesign={true}
          id="visibility"
          disabled={params.data.id === isEditing ? false : true}
          checked={params.value}
          onChange={(e: any) => handleToggleClick(params, e.target.checked)}
        />
      )
    );
  };

  const linkRenderer = (params: any) => {
    return (
      <a href={params.data.url} target="_blank" rel="noreferrer">
        {params.data.url}
      </a>
    );
  };

  const columns = [
    {
      headerName: "Link title (Max 50 chars)",
      field: "title",
      flex: 6,
      suppressSizeToFit: true,
      editable: isAdmin,
      cellEditor: "agTextCellEditor",
      cellEditorParams: { maxLength: 50 },
      cellClass: (params) => {
        return validateFormInputForSpecialChar(params.value)
          ? "ag-cell-value-invalid"
          : null;
      },
    },
    {
      headerName: "URL",
      field: "url",
      flex: 14,
      suppressSizeToFit: true,
      editable: isAdmin,
      cellEditor: "agTextCellEditor",
      cellRenderer: linkRenderer,
      cellClass: (params) => {
        return validateProfessionalUrl(params.value)
          ? null
          : "ag-cell-value-invalid";
      },
    },
    isAdmin
      ? {
          headerName: "Client visibility",
          field: "visibility",
          flex: 3,
          suppressSizeToFit: true,
          editable: false,
          cellRenderer: toggleRender,
        }
      : null,
    isAdmin
      ? {
          headerName: "",
          field: "actions",
          flex: 2,
          suppressSizeToFit: true,
          editable: false,
          cellRenderer: actionCellRenderer,
        }
      : null,
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    editable: false,
    isResizable: true,
  };

  const listColumns = columns.filter((col) => col != null);
  const data = Array.isArray(links)
    ? isInternalUser
      ? links.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
      : links
          .filter((x) => x.visibility == true)
          ?.sort((a, b) => a.title.localeCompare(b.title, undefined, { sensitivity: 'base' }))
    : [];

  const listRef = useRef<{ api: GridApi }>();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        if (!isEditing) return;
        listRef.current?.api.stopEditing();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, [isEditing, projectId]);

  return (
    <section className="quick-links">
      <span>{"Quick Links"}</span>
      {isAdmin && data.length > 0 && (
        <div className="add-new-link-button">
          <AppButton
            id="add-link-button"
            onClick={handleAddLink}
            size="medium"
            variant="secondary"
            className="containedSecondary"
            disabled={isEditing !== null || isNewLink}
          >
            <AppIcon icon={add} className="add-icon" />
            {en_translation.add_new_link}
          </AppButton>
        </div>
      )}
      {!isLoading && data.length < 1 && (
        <div className="add-quick-links">
          <div className="no-link-found">
            <span className="no-links">{en_translation.no_link_found}</span>
          </div>
          <div>
            <span className="start-addition-links">
              {en_translation.start_add_quick_link}
            </span>
          </div>
          {isAdmin && (
            <div>
              <AppButton
                id="add-link-button"
                onClick={handleAddLink}
                size="medium"
                className="containedSecondary"
              >
                <AppIcon icon={add} className="add-icon" />
                {en_translation.add_quick_link}
              </AppButton>
            </div>
          )}
        </div>
      )}
      {data.length > 0 && (
        <div ref={containerRef} className="quick-links-list">
          {(Array.isArray(titleErrorList) &&
            titleErrorList?.filter((x) => x.title).length > 0) ||
          (Array.isArray(urlErrorList) &&
            urlErrorList?.filter((x) => x.url).length > 0) ? (
            <div>
              <AppAlert severity="error" className="error-alert-custom">
                {"Warning: "}
                {titleErrorList?.filter((x) => x.title)[0]?.title}{" "}
                {urlErrorList?.filter((x) => x.url)[0]?.url}
              </AppAlert>
            </div>
          ) : (
            <div className="header-padding"></div>
          )}
          <List
            rowHeight={30}
            withStickyScroll
            rowSelection="single"
            firstColumnBorderRight={true}
            columnConfig={listColumns}
            defaultColDef={defaultColDef}
            deltaRowDataMode={true}
            rowData={data}
            paginate={false}
            onGridReady={onGridReady}
            setDomLayout="normal"
            gridOptions={{
              headerHeight: 30,
              defaultColDef: defaultColDef,
              onRowEditingStopped: onRowEditingStopping,
              suppressClickEdit: true,
              sizeColumnsToFit: true,
              editType: "fullRow",
              autoSizeStrategy: {
                type: "fitGridWidth",
                defaultMinWidth: 100,
              },
            }}
          />
        </div>
      )}
    </section>
  );
};

export default QuickLinks;
