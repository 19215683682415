import * as type from "../action-types";
import {
  IProjectMetaData,
  Project,
} from "../../components/createProject/models/project";
import { IUser } from "../../models/user";
import {
  IUserRole,
  IAppPlatformUpdate,
  IMyProjectRights,
  IWrikeSyncConfigs,
} from "../../models/IUserAccess";
import {
  IPagination,
  getDefaultPaginationModel,
} from "../../models/IPagination";
import { IAnnouncements } from "../../models/IAnnouncements";
import {
  getAnnouncementChunks,
  flushAnnouncements,
} from "../operations/projectDetailsOperations";

const initialState: {
  list: Project[];
  record: Project | null;
  updating: boolean;
  updatingError: string;
  loading: boolean;
  users: IUser[];
  userAccess: IUserRole[];
  usersRequested: boolean;
  userAccessRequested: boolean;
  userGridPagination: IPagination;
  userAddRequested: boolean;
  usersUpdateRequested: boolean;
  showAnnouncementFailure: boolean;
  announcements: IAnnouncements[];
  savedAnnouncement: object;
  announcementPaginationData: object;
  updateManageUsersResponse: IAppPlatformUpdate[];
  myProjectRightsData: IMyProjectRights;
  removeUserGridDisplay: boolean;
  deletedAnnouncement: object;
  showDeleteAnnouncementFailure: boolean;
  showReadAnnouncementFailure: boolean;
  wrikeSyncData: IWrikeSyncConfigs | null;
  isWrikeSyncUpdated: boolean;
  isToggleSyncApiAvailable: boolean;
  activeFeatures: string[];
  extendProjectExpirationResponse: object;
  projectMetaData: IProjectMetaData;
  showExpirationModel: boolean;
  isFeatureSupported: boolean | null;
  patchProjectData: object;
  uploadedFile: string
} = {
  usersRequested: false,
  userAccessRequested: false,
  usersUpdateRequested: false,
  userAddRequested: false,
  userGridPagination: getDefaultPaginationModel(),
  list: [],
  record: null,
  updating: false,
  updatingError: "",
  loading: false,
  users: [],
  userAccess: [],
  updateManageUsersResponse: [],
  showAnnouncementFailure: false,
  showDeleteAnnouncementFailure: false,
  showReadAnnouncementFailure: false,
  announcements: [],
  savedAnnouncement: {},
  announcementPaginationData: {
    isAllAnnouncementsFetched: false,
    chunk: 1,
  },
  myProjectRightsData: {},
  removeUserGridDisplay: false,
  deletedAnnouncement: {},
  wrikeSyncData: null,
  isWrikeSyncUpdated: false,
  isToggleSyncApiAvailable: false,
  activeFeatures: [],
  extendProjectExpirationResponse: {},
  projectMetaData: {},
  showExpirationModel: false,
  isFeatureSupported: null,
  patchProjectData: {},
  uploadedFile: ""
};

// handles actions
// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case type.GET_PROJECT_LIST_REQUEST:
    case type.GET_PROJECT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case type.GET_PROJECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        list:
          action.payload && typeof action.payload == "object"
            ? action.payload.data?.data
            : []
      };
    case type.GET_PROJECT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        list: []
      };
    case type.GET_PROJECT_DETAILS_SUCCESS:
      return { ...state, record: action.payload.data, loading: false };
    case type.GET_PROJECT_DETAILS_FAILURE:
      return { ...state, record: {}, loading: false };
    case type.FLUSH_PROJECT_DETAILS:
      return { ...state, record: {}, users: [], loading: false, myProjectRightsData: {}, isFeatureSupported: null, patchProjectData: {} };
    case type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_REQUEST:
      return { ...state, updating: true, updatingError: "" };
    case type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_SUCCESS:
      return { ...state, updating: false, updatingError: "" };
    case type.UPDATE_PROJECT_APP_BUNDLE_PUBLISH_STATUS_FAILURE:
      return { ...state, updatingError: "error", updating: false };
    case type.GET_PROJECT_USERS_LIST_REQUEST:
      return {
        ...state,
        usersRequested: true,
        users: null,
      };
    case type.GET_PROJECT_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersRequested: false,
        users: action?.payload?.data?.records || [],
        userGridPagination: action?.payload?.data?.pagination || {},
      };
    case type.GET_PROJECT_USERS_LIST_FAILURE:
      return {
        ...state,
        usersRequested: false,
        users: [],
      };
    case type.GET_PROJECT_USERS_ACCESS_LIST_REQUEST:
      return {
        ...state,
        userAccessRequested: true,
        users: null,
      };
    case type.GET_PROJECT_USERS_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        userAccessRequested: false,
        userAccess: action?.payload?.data?.users || [],
      };
    case type.GET_PROJECT_USERS_ACCESS_LIST_FAILURE:
      return {
        ...state,
        userAccessRequested: false,
        userAccess: [],
      };
    case type.ADD_MANAGE_USER_REQUEST:
      return {
        ...state,
        userAddRequested: true,
      };
    case type.ADD_MANAGE_USER_SUCCESS:
    case type.ADD_MANAGE_USER_FAILURE:
      return {
        ...state,
        userAddRequested: false,
      };
    case type.UPDATE_MANAGE_USER_REQUEST:
      return {
        ...state,
        usersUpdateRequested: true,
        updateManageUsersResponse: [],
        removeUserGridDisplay: false,
      };
    case type.UPDATE_MANAGE_USER_SUCCESS:
      return {
        ...state,
        usersUpdateRequested: false,
        updateManageUsersResponse: action.payload.data || [],
        removeUserGridDisplay: false,
      };
    case type.UPDATE_MANAGE_USER_RESET:
      return {
        ...state,
        usersUpdateRequested: false,
        updateManageUsersResponse: [],
      };
    case type.UPDATE_MANAGE_USER_FAILURE:
      return {
        ...state,
        usersUpdateRequested: true,
        removeUserGridDisplay: true,
      };
    case type.CREATE_ANNOUNCEMENT_SUCCESS:
      return { ...state, savedAnnouncement: action.payload };

    case type.CREATE_ANNOUNCEMENT_FAILURE:
      return { ...state, showAnnouncementFailure: true };

    case type.FLUSH_ANNOUCEMENT_NOTIFICATION:
      return {
        ...state,
        showAnnouncementFailure: false,
        showDeleteAnnouncementFailure: false,
      };

    case type.GET_ANNOUNCEMENTS_CHUNK:
      return { ...state, loading: false };

    case type.GET_ANNOUNCEMENTS_SUCCESS:
      return getAnnouncementChunks(state, action.payload);

    case type.FLUSH_ANNOUNCEMENTS:
      return flushAnnouncements(state);

    case type.UPDATE_ANNOUNCEMENT_SUCCESS:
      return { ...state, savedAnnouncement: action.payload };

    case type.UPDATE_ANNOUNCEMENT_FAILURE:
      return { ...state, showAnnouncementFailure: true };

    case type.GET_MY_PROJECT_RIGHT_REQUEST:
      return { ...state, loading: true };
    case type.GET_MY_PROJECT_RIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        myProjectRightsData:
          action.payload && typeof action.payload === "object"
            ? action.payload
            : {},
      };
    case type.GET_MY_PROJECT_RIGHT_FAILURE:
      return {
        ...state,
        loading: false,
        myProjectRightsData: {},
      };

    case type.DELETE_ANNOUNCEMENT_SUCCESS:
      return { ...state, deletedAnnouncement: action.payload };

    case type.DELETE_ANNOUNCEMENT_FAILURE:
      return { ...state, showDeleteAnnouncementFailure: true };

    case type.READ_ANNOUNCEMENT_FAILURE:
      return { ...state, showReadAnnouncementFailure: true };

    case type.GET_WRIKE_SYNC_SETTINGS_SUCCESS:
      return { ...state, wrikeSyncData: action.payload };

    case type.UPDATE_WRIKE_SYNC_SETTINGS_SUCCESS:
      return { ...state, isWrikeSyncUpdated: true };

    case type.FLUSH_WRIKE_SYNC_NOTIFICATION:
      return { ...state, isWrikeSyncUpdated: false };

    case type.CHECK_TOGGLE_SYNC_STATUS_SUCCESS:
      return { ...state, isToggleSyncApiAvailable: !!action.payload.data };

    case type.FLUSH_TOGGLE_SYNC_STATUS:
      return { ...state, isToggleSyncApiAvailable: false };

    case type.FLUSH_MANAGE_USERS_GRID:
      return { ...state, users: [], userAccess: [] };

    case type.GET_PROJECT_ACTIVE_FEATURES:
      return {
        ...state,
        activeFeatures: null,
      };
    case type.GET_PROJECT_ACTIVE_FEATURES_SUCCESS:
      return {
        ...state,
        activeFeatures: action?.payload?.data || [],
      };
    case type.GET_PROJECT_ACTIVE_FEATURES_FAILURE:
      return {
        ...state,
        activeFeatures: [],
      };
    case type.EXTEND_PROJECT_EXPIRATION_SUCCESS:
    case type.EXTEND_PROJECT_EXPIRATION_FAILURE: //
      return {
        ...state,
        extendProjectExpirationResponse: action.payload,
      };
    case type.EXTEND_PROJECT_EXPIRATION_FLUSH:
      return {
        ...state,
        extendProjectExpirationResponse: {},
      };

    case type.GET_PROJECT_METADATA_SUCCESS:
      return {
        ...state,
        projectMetaData: action?.payload?.data || {},
      };

    case type.GET_PROJECT_METADATA_FAILURE:
      return {
        ...state,
        projectMetaData: {},
      };

    case type.SET_SHOW_EXPIRATION_MODEL:
      return {
        ...state,
        showExpirationModel: action.paylaod,
      };

    case type.GET_PROJECT_SUPPORTED_FEATURES_SUCCESS:
    return {
      ...state,
      isFeatureSupported: action?.payload?.data,
    };

    case type.GET_PROJECT_SUPPORTED_FEATURES_FAILURE:
      return {
        ...state,
        isFeatureSupported: null,
      };
    case type.PATCH_PROJECT:
      return { ...state, loading: true};
    case type.PATCH_PROJECT_SUCCESS:
      return { ...state, patchProjectData: action.payload, loading: false};
    case type.PATCH_PROJECT_FAILURE:
      return { ...state, patchProjectData: {}, loading: false};

    case type.ADD_PROJECT_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case type.ADD_PROJECT_DOCUMENT_SUCCESS:
      return { ...state, uploadedFile: action.payload, loading: false };
    case type.ADD_PROJECT_DOCUMENT_FAILURE:
      return { ...state, loading: false };
    case type.FLUSH_PROJECT_UPLOADED_FILE:
      return { ...state, uploadedFile: '' };
    case type.DELETE_PROJECT_DOCUMENT_REQUEST:
      return { ...state, loading: true };
    case type.DELETE_PROJECT_DOCUMENT_SUCCESS:
    case type.DELETE_PROJECT_DOCUMENT_FAILURE:
      return { ...state, loading: false };

    default:
      return state;
  }
};
