import { authProvider } from "./authProvider";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { showActionToast } from "../store/actions/notification-actions";
import t from "../localization/en/translation.json";
import { API_URL } from "../store/api";
import { showChangeMailPopUp } from "../store/actions/me-actions";
import moment from "moment";
import { REPLAY_ERROR, SessionKeys } from "./Constants";
import { getServerTime } from "../store/actions/settings-actions";
import { getProjectClosureDetails } from "../store/apis/projectApi";
const URLSForUnAuthPage = [`${API_URL}/api/v1/me`];
const FIVE_SECONDS = 5;
const RETRY_REQUEST_COUNT = "Retry-Request-Count";

let unauth = false;
let callAPI = true;
let urlToAllow = "";
let returnRequest;

const detectEmailChange = (
  store: { dispatch: (arg0: { type: string; payload: any }) => void },
  response: AxiosResponse<any>
) => {
  const { email, previousEmail } = response?.data;
  store.dispatch(
    showChangeMailPopUp({
      showEmailChangePopUp: true,
      currentEmail: email,
      previousEmail: previousEmail,
    })
  );
  const { url, method } = response.config;
  callAPI = false;
  urlToAllow = url || "";
  const triggerEmailChangeAPI = () => {
    if (method) {
      axios[method](url)
        .then((response) => {
          if (response.status === 200) {
            store.dispatch(
              showChangeMailPopUp({
                showEmailChangePopUp: false,
                currentEmail: "",
                previousEmail: "",
              })
            );
            callAPI = true;
            urlToAllow = "";
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          throw error;
        });
    } else {
      console.log("method is not defined");
    }
  };
  setTimeout(
    triggerEmailChangeAPI,
    (parseInt(
      process.env.REACT_APP_TIME_INTERVAL_TO_TRIGGER_EMAIL_CHANGE_API ?? ""
    ) || FIVE_SECONDS) * 1000
  );
};

const retryRequest = (store, error) => {
  let retryRequestCount = 0;
  if (
    error.response.config &&
    error.response.config.headers[RETRY_REQUEST_COUNT]
  ) {
    retryRequestCount = parseInt(
      error.response.config.headers[RETRY_REQUEST_COUNT]
    );
  }

  if (retryRequestCount > 2 && error.response.status === REPLAY_ERROR) {
    store.dispatch(
      showActionToast({
        message: "",
        type: "error",
        status: REPLAY_ERROR,
        redirectURL: "error",
        code: error.response.data[0].code || "",
      })
    );
  } else if (retryRequestCount > 2) {
    return Promise.reject(error);
  } else {
    if (retryRequestCount === 1 && error.response.status === REPLAY_ERROR) {
      sessionStorage.removeItem(SessionKeys.CE_SERVER_TIME);
      store.dispatch(getServerTime());
    }
    error.response.config.headers[RETRY_REQUEST_COUNT] = retryRequestCount + 1;
    return new Promise((resolve) =>
      setTimeout(resolve, retryRequestCount * 1000)
    ).then(() => axios(error.response.config));
  }
};

const ANONYMOUS_ACCESS_URLS = ["settings/server-time"];

const isAnonymousAccessURL = (url) => {
  return ANONYMOUS_ACCESS_URLS.some((bypassUrl) => url.includes(bypassUrl));
};

// eslint-disable-next-line
export default {
  setupInterceptors: (store) => {
    axios.interceptors.request.use(async (request: AxiosRequestConfig) => {
      if (callAPI || (!callAPI && urlToAllow === request.url)) {
        if (
          process.env.REACT_APP_AZURE_AD_API_SCOPE &&
          process.env.REACT_APP_AZURE_AD_AUTHORITY &&
          !isAnonymousAccessURL(request.url)
        ) {
          // Only fetch and set accessToken if Authorization header does not exist
          if (!request.headers || !request.headers["Authorization"]) {
            const accessToken = await authProvider.getToken();
            request.headers = {
              ...request.headers,
              Authorization: `Bearer ${accessToken}`,
            };
          }

          let retryCount = 0;
          if (request.headers && request.headers[RETRY_REQUEST_COUNT]) {
            retryCount = request.headers[RETRY_REQUEST_COUNT];
          }
          const userTimeDeviation = store.getState().settings.userTimeDeviation;
          const updatedTime = moment().add(userTimeDeviation, "milliseconds");
          request.headers = {
            ...request.headers,
            "Request-Timestamp": updatedTime.utc().valueOf(),
          };

          if (!request.headers.hasOwnProperty("Content-Type")) {
            request.headers["Content-Type"] = "application/json";
          }

          if (retryCount) {
            request.headers[RETRY_REQUEST_COUNT] = retryCount;
          }
        }
        returnRequest = request;
      } else {
        //when 211 status then abort all other calls except this(211 api)
        const controller = new AbortController();
        controller.abort();
        returnRequest = controller.signal;
      }
      return returnRequest;
    });

    axios.interceptors.response.use(
      (response) => {
        if (response.status === 211) {
          //detect the api and trigger it in after evry 5 sec untill it gives 200 status code.
          detectEmailChange(store, response);
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          for (const url of URLSForUnAuthPage) {
            if (error.request.responseURL === url) {
              unauth = true;
              break;
            }
          }
          store.dispatch(
            showActionToast({
              message: t.permission_denied_error,
              type: "error",
              status: 401,
              redirectToUnauth: unauth,
            })
          );
        } else if (error.response.status === 404) {
          // not-found
        } else if (error.response.status === 422) {
          // process-error
        } else if (error.response.status >= 500) {
          return retryRequest(store, error);
        } else if (error.response.status === 403) {
          for (const url of URLSForUnAuthPage) {
            if (error.request.responseURL === url) {
              unauth = true;
              break;
            }
          }
          if (error.request.responseURL.includes("/projects/close/")) {
            getProjectClosureDetails({
              projectId: error.request.responseURL.split('/projects/close/')[1],
            })
              .then((response) => {
                if (response.data.projectClosedByUserName) {
                  store.dispatch(
                    showActionToast({
                      message: `Project ${
                        response.data.projectName
                      } has already been closed by ${
                        response.data.projectClosedByUserName
                      } (${response.data.projectClosedByUserEmail}) on ${moment(
                        response.data.projectClosedDate
                      ).format("DD/MM/YYYY")}`,
                      type: "message",
                      status: 403,
                    })
                  );
                } else {
                  store.dispatch(
                    showActionToast({
                      message: t.permission_denied_error,
                      type: "error",
                      status: 403,
                      redirectToUnauth: unauth,
                    })
                  );
                }
              })
              .catch(() => {
                store.dispatch(
                  showActionToast({
                    message: t.permission_denied_error,
                    type: "error",
                  })
                );
              });
          } else {
            store.dispatch(
              showActionToast({
                message: t.permission_denied_error,
                type: "error",
                status: 403,
                redirectToUnauth: unauth,
              })
            );
          }
        } else if (error.response.status === REPLAY_ERROR) {
          return retryRequest(store, error);
        } else if (error.response.status === 400) {
          store.dispatch(
            showActionToast({
              message: error.response.data[0].errorMessage,
              type: "error",
              status: 400,
              redirectToUnauth: false,
              code: error.response.data[0].code || "",
            })
          );
        }
        return Promise.reject(error);
      }
    );
  },
};
