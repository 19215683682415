import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NOTIFICATION,
  notificationType,
} from "../../store/actions/admin-portal-communication-actions";
import { AppInput, AppModal, AppFormErrorText } from "../../theme";
import { APP_STATE } from "../../store/reducers";
import { FormControl, RadioGroup } from "@material-ui/core";
import AppRichTextBox from "../appRichTextBox/appRichTextBox";
import AppRadioButton from "../../theme/appRadioButton/appRadioButton";
import AppDatePicker from "../../theme/appDatePicker/appDatePicker";
import { DATE_TIME_SCHEDULER } from "../adminAddEventModal/adminAddEventModalConstants";
import AppButton from "../../theme/appButton/appButton";
import t from "../../localization/en/translation.json";
import AppCheckbox from "../../theme/appCheckbox/appCheckbox";
import {
  MemberDisplay,
  use100PerWidthStyle,
  useBoxShadowBottomDividerStyle,
  useFlexColumnStyle,
  useFlexGrowTo1Style,
  useFlexJConententSpaceBtwnStyle,
  useFlexJConententStartStyle,
  useFlexRowStyle,
  useFlexVcenteredStyle,
  useHeight30pxStyle,
  useMaxWidthTo100Perc,
  usePaddingBottom8pxStyle,
  usePaddingTop10pxStyle,
} from "./Parts";
import {
  getPlainText,
  validateFormInputForSpecialChar,
} from "../../utils/helper-utility";
import { getTodayDate } from "./adminPortalComunicationConstant";
import { notificationStatus } from "../../utils/Constants";
import moment from "moment";

const changeTimeToLastSecondOftheDay = (date: Date) =>
  new Date(date.setHours(23, 59, 59));
const changeTimeToStartOftheDay = (date: Date) =>
  new Date(date.setHours(0, 0, 0));
type UserTypes = ("Internal" | "External")[];
export type Data = {
  id?: string;
  version?: string;
  content?: string;
  contentDelta?: string;
  userTypes?: UserTypes;
  status?: { startDate?: Date; endDate?: Date; value?: boolean };
};
type Props = {
  notificationType: keyof typeof notificationType;
  cretateNotificationAction: any;
  updateNotificationAction: any;
  notificationTitle: string;
  setOpenModal: (
    value: ((prevState: {} | null) => {} | null) | {} | null
  ) => void;
  initial?: Data;
};
export const ReleaseNoteFormModal = ({
  notificationType,
  cretateNotificationAction,
  updateNotificationAction,
  notificationTitle,
  setOpenModal,
  initial: {
    id = undefined,
    version = "",
    content = "",
    contentDelta = "{}",
    userTypes = [] as unknown as UserTypes,
    status: {
      startDate = new Date(),
      endDate = new Date(),
      value = undefined,
    } = {},
  } = {},
}: Props) => {
  const messageDelta = useRef(JSON.parse(contentDelta));
  const dispatch = useDispatch();
  const [dirtyVersion, setVersion] = useState(version);
  const [releaseNoteContent, setReleaseNoteContent] = useState(content);
  const [active, setActive] = useState(value);
  const [_startDate, setStartDate] = useState(
    changeTimeToStartOftheDay(startDate)
  );
  const [_endDate, setEndDate] = useState(
    changeTimeToLastSecondOftheDay(endDate)
  );
  const [saveTitle, setSaveTitle] = useState(() => (id ? t.Update : t.Add));
  const [_userTypes, setUserTypes] = useState(() => userTypes);

  // eslint-disable-next-line
  const change: NOTIFICATION = {
    title: dirtyVersion,
    message: releaseNoteContent,
    messageDelta: JSON.stringify(messageDelta.current),
    description: dirtyVersion,
    status: active ? "Active" : "InActive",
    type: notificationType,
    userTypes: _userTypes,
    startDateTime: _startDate.getTime(),
    endDateTime: _endDate.getTime(),
  };
  const onSave = useCallback(() => {
    if (id == null) {
      dispatch(cretateNotificationAction(change));
    } else {
      dispatch(
        updateNotificationAction({
          ...change,
          id,
        })
      );
    }
    setSaveTitle(() => (id ? t.Updating : t.Adding));
  }, [
    dispatch,
    id,
    // no-autofix/react-hooks/exhaustive-deps
    change,
    cretateNotificationAction,
    updateNotificationAction,
  ]);

  const savingStatus = useSelector<APP_STATE>(
    ({ portalCommunications }) => portalCommunications.status
  );
  const didSaveHappenedRef = useRef(savingStatus);
  // when saving of change is complete, we can close the modal as side effect
  useEffect(() => {
    if (savingStatus == null && didSaveHappenedRef.current === notificationStatus.Saving) {
      setOpenModal(false);
    } else {
      didSaveHappenedRef.current = savingStatus;
    }
  }, [savingStatus, setOpenModal, didSaveHappenedRef]);

  // check upon all fields have valid value to enable save button
  const enableSave =
    dirtyVersion != null &&
    !validateFormInputForSpecialChar(dirtyVersion) &&
    !validateFormInputForSpecialChar(getPlainText(releaseNoteContent)) &&
    dirtyVersion.trim().length &&
    releaseNoteContent != null &&
    releaseNoteContent.trim().length &&
    _userTypes?.length &&
    active != null &&
    !isNaN(_startDate.getTime()) &&
    !isNaN(_endDate.getTime());

  return (
    <AppModal
      size={"md"}
      showModal={true}
      onModalClose={() => setOpenModal(false)}
      title={notificationTitle}
      onConfirm={() => setOpenModal(null)}
    >
      <FormControl className={"admin-generic-modal"} fullWidth={true}>
        <div className={`app-row ${use100PerWidthStyle().root}`}>
          <div className={"app-col-xs-2"}>
            <AppInput
              id={"notification_caption"}
              label={t.portal_communication_filed_notification_caption}
              value={dirtyVersion}
              onChange={(ele) => setVersion(ele.target.value)}
            />
            {validateFormInputForSpecialChar(dirtyVersion) && (
              <AppFormErrorText>
                {t.all_ssp_validate_special_character_in_text_error.replace(
                  "{1}",
                  dirtyVersion.trim().split("")[0]
                )}
              </AppFormErrorText>
            )}
          </div>
        </div>
        <div className={`app-row ${use100PerWidthStyle().root}`}>
          <div className={"app-col-xs-4"}>
            <AppRichTextBox
              id={"notification_content"}
              onTextChange={(content, delta, source, editor) => {
                messageDelta.current = editor.getContents();
                setReleaseNoteContent(content);
              }}
              placeholder={t.portal_communication_filed_notification_details}
              bannerMessage={releaseNoteContent}
              isValidMessage={
                !validateFormInputForSpecialChar(
                  getPlainText(releaseNoteContent).trim()
                )
              }
              errorMsg={
                !validateFormInputForSpecialChar(
                  getPlainText(releaseNoteContent).trim()
                )
                  ? t.admin_rich_text_error
                  : t.all_ssp_validate_special_character_in_text_error.replace(
                      "{1}",
                      getPlainText(releaseNoteContent).trim().split("")[0]
                    )
              }
            />
          </div>
        </div>
        <div
          className={`app-row ${use100PerWidthStyle().root} ${
            useBoxShadowBottomDividerStyle().root
          }`}
        >
          <MemberDisplay showText={true} showIcon={false} />
          <div
            className={`app-col-xs-1 ${useFlexRowStyle().root} ${
              useFlexVcenteredStyle().root
            } 
                    ${useFlexGrowTo1Style().root} ${
              useMaxWidthTo100Perc().root
            } 
                    ${useHeight30pxStyle().root} ${
              usePaddingBottom8pxStyle().root
            }`}
          >
            <AppCheckbox
              id={"internal"}
              key={"internal"}
              name={"internal"}
              value={"internal"}
              checked={_userTypes?.includes("Internal")}
              onChange={(e) =>
                setUserTypes(
                  e.target.checked
                    ? [..._userTypes, "Internal"]
                    : _userTypes?.filter((value) => value !== "Internal")
                )
              }
            >
              Internal Users
            </AppCheckbox>
            <AppCheckbox
              id={"external"}
              key={"external"}
              name={"external"}
              value={"external"}
              checked={_userTypes?.includes("External")}
              onChange={(e) =>
                setUserTypes(
                  e.target.checked
                    ? [..._userTypes, "External"]
                    : _userTypes?.filter((value) => value !== "External")
                )
              }
            >
              External Users
            </AppCheckbox>
          </div>
        </div>
        <div
          className={`app-row ${use100PerWidthStyle().root} ${
            usePaddingTop10pxStyle().root
          }`}
        >
          <div className={`app-col-xs-1 ${useFlexColumnStyle().root}`}>
            <RadioGroup
              id={"notification_status"}
              name={"status"}
              value={active == null ? "" : active ? "active" : "inactive"}
            >
              <AppRadioButton
                id={"active"}
                key={"active"}
                value={"active"}
                onChange={() => setActive(true)}
              >
                Active
              </AppRadioButton>
              <AppRadioButton
                id={"inactive"}
                key={"inactive"}
                value={"inactive"}
                onChange={() => setActive(false)}
              >
                Inactive
              </AppRadioButton>
            </RadioGroup>
          </div>
          <div
            className={`app-col-xs-2 ${useFlexRowStyle().root} ${
              useFlexJConententSpaceBtwnStyle().root
            }`}
          >
            <AppDatePicker
              id={"notification_start_date"}
              format={DATE_TIME_SCHEDULER.format}
              label={"From"}
              minDate={getTodayDate()}
              minDateMessage={""}
              maxDateMessage={""}
              value={_startDate?.toDateString()}
              invalidDateMessage={`${t.admin_notify_date_error}`}
              customEdit={false}
              onChange={(date) => {
                if(!moment(date).isBefore(moment(), "day") && !isNaN(date?.getTime())){
                  setStartDate(changeTimeToStartOftheDay(date))
                  const plusDays = moment(date).add(1, 'days').toDate()
                  setEndDate(plusDays)
                }else{
                  setStartDate(new Date(""))
                }
              }
              }
            />
            <AppDatePicker
              id={"notification_end_date"}
              format={DATE_TIME_SCHEDULER.format}
              label={"To"}
              minDate={_startDate}
              minDateMessage={""}
              value={_endDate?.toDateString()}
              customEdit={false}
              onChange={(date) =>
                !isNaN(date?.getTime())
                  ? setEndDate(changeTimeToLastSecondOftheDay(date))
                  : setEndDate(new Date(""))
              }
            />
          </div>
        </div>
        <div className={"app-row"}>
          <div
            className={`app-col-xs-1 ${useFlexRowStyle().root} ${
              useFlexJConententStartStyle().root
            }`}
          >
            <AppButton
              id={"notification_save"}
              disabled={!enableSave}
              style={{ marginRight: "10px" }}
              onClick={onSave}
            >
              {saveTitle}
            </AppButton>
            <AppButton
              id={"notification_cancel_change"}
              variant={"outlined"}
              onClick={() => setOpenModal(false)}
            >
              Cancel
            </AppButton>
          </div>
        </div>
      </FormControl>
    </AppModal>
  );
};
