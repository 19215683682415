import React from "react";
import { FormControl, ListItemIcon, MenuItem, Select } from "@mui/material";
import "./appSorting.scss";
import { AppIcon } from "../../theme";
import { angleDown, check } from "../../theme/icons";
import sortIcon from "../../assets/images/icons/sorting.svg";

interface ISortDataModal {
  key: string;
  value: string;
}
interface IAppSelectionProps {
  sortData: Array<ISortDataModal>;
  sortValue: string;
  handleSortChange: Function;
}

const AppSorting: React.FC<IAppSelectionProps> = ({sortData, sortValue, handleSortChange}) => {
  return (
    <div className="app-sort-wrapper">
      <img src={sortIcon}  alt="img"/>
      <FormControl size="small">
        <Select
          value={sortValue}
          displayEmpty
          onChange={(e) => handleSortChange(e.target.value)}
          IconComponent={(props) => <AppIcon icon={angleDown} {...props} />}
        >
          {sortData.map((item) => (
            <MenuItem className="sort-menu-item" key={item.key} value={item.key}>
              {item.value}
              {sortValue === item.key && (
                <ListItemIcon>
                  <AppIcon icon={check} style={{fontSize: 17}} />
                </ListItemIcon>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default AppSorting;
