import 'date-fns';
import React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

import './appDateTimePicker.scss';

interface IProps {
  id?: string;
  onChange?: Function;
  format: string;
  value: string;
  label?: string,
  minDate?: Date,
  maxDate?: Date,
  errorMessage?: boolean,
  minDateMessage?: string
  maxDateMessage?: string
}

interface IState {
  open: boolean
}

class AppDateTimePicker extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleChange = (event) => {
    const { onChange } = this.props;

    onChange && event && onChange(event._d)
  }

  handleCloseCalendar = () => {
    this.setState({ open: false })
  }

  render() {
    const { id = "app-date-time-picker", label } = this.props;
    const { open } = this.state;
    const defaultValue = this.props.value ? moment(this.props.value) : null

    return (
      <div className="app-date-time-picker" >
        <div id={id}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              label={label}
              value={defaultValue}
              onChange={this.handleChange}
              onAccept={this.handleCloseCalendar}
              onClose={this.handleCloseCalendar}
              timeSteps={{ hours: 1, minutes: 1 }}
              open={open}
              slotProps={{
                textField: {
                  onClick: () => this.setState({ open: true })
                },
                openPickerButton: { onClick: () => this.setState({ open: true }) },
                popper: {
                  placement: 'top-start',
                  style: { "top": "-60px" }
                },
                actionBar: {
                  actions: ['accept']
                }
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    );
  }

}

export default AppDateTimePicker;